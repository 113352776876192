import React, { useState } from 'react';

const AvatarSelectorModal = ({ ai, ais, setAi, baseURL }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectAi = (ai) => {
    setAi(ai);
    setIsModalOpen(false); // close modal after selection
  };

  return (
    <>
      {/* Trigger button to open the modal */}
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-blue-500 text-white p-2 rounded"
      >
        Select an AI to Talk to
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
          <div className="relative p-8 bg-gray-800 text-gray-300 text-sm w-full max-w-4xl m-auto flex-col flex rounded-lg">
            {/* Close button */}
            <span
              className="absolute top-0 right-0 p-4 cursor-pointer"
              onClick={() => setIsModalOpen(false)}
            >
              &times;
            </span>

            <div className="mb-4">
              <h1 className="text-lg font-bold">Select an AI</h1>
            </div>

            {/* Grid of ais */}
            <div className="grid md:grid-cols-4 grid-cols-2 gap-4">
              {ais.map((ai) => (
                <div
                  key={ai.id}
                  className={`p-2 rounded-lg cursor-pointer hover:bg-gray-900`}
                  onClick={() => selectAi(ai)}
                >
                  <div className="flex justify-center">
                    <img
                      src={`${baseURL}/${ai.image}`}
                      alt={ai.title}
                      className="w-24 h-24 object-cover rounded-full"
                    />
                  </div>
                  <div className="text-center mt-2">{ai.name}
                  <div className="text-sm">{ai.cat} &mdash; 📈 {ai.pop}</div></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AvatarSelectorModal;
