import React, { useState } from 'react';

const ApiKeyModal = ({apiKey, setApiKey}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    if (!apiKey) setApiKey(-1);
  };

  const handleApiKeyChange = (e) => {
    setApiKey(e.target.value);
    localStorage.setItem('apiKey', e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform any necessary validation or API key saving logic here
    setApiKey(e.target.value);
    closeModal();
  };

  return;
  return (
    <div>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={openModal}
      >
        Enter OpenAI API Key
      </button>

      {(isOpen || apiKey === '') && (

        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="w-full md:w-1/2 bg-white rounded shadow p-8">
            <h2 className="text-xl font-bold mb-4">Enter API Key</h2>

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="apiKey">
                  API Key
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="apiKey"
                  type="text"
                  value={apiKey}
                  onChange={handleApiKeyChange}
                  placeholder="Your OpenAI API key"
                />
              </div>

              <div className="flex justify-end">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                  type="submit"
                >
                  Save
                </button>
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApiKeyModal;
