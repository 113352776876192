import React from 'react';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyToClipboardButton = ({ contentRef }) => {
  const handleCopy = () => {
    // Check if the ref exists and has a current value
    if (contentRef.current) {
      // Select the text content of the div
      const range = document.createRange();
      range.selectNodeContents(contentRef.current);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      // Execute copy command
      try {
        document.execCommand('copy');
        // If copying was successful, you can show a tooltip or snackbar
        console.log('Content copied!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }

      // Clear the selection if you don't want it to stay
      selection.removeAllRanges();
    }
  };

  return (
    <div className="absolute top-8 right-0">
      <IconButton size="small" onClick={handleCopy} >
        <ContentCopyIcon className="text-secondary" />
      </IconButton>
    </div>
  );
};

export default CopyToClipboardButton;
