import React, { useState } from 'react';
import ApiKeyModal from './ApiKeyModal';
import SelectAI from './SelectAI';

const Navbar = ({apiKey, setApiKey, ai, ais, setAi, baseURL}) => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const toggleMenu = () => {
    setIsHamburgerOpen(!isHamburgerOpen);
  };

  return (
    <nav className="fixed top-0 left-0 right-0 bg-gray-800 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <a href="/">
                <img className="h-8 w-8" src="/logo300.svg" alt="Logo" />
              </a>
            </div>
            <div className="ml-5 text-gray-300">
              <i>Type of us</i>: Realtime conversations with your favorite AI character
            </div>
            <div className="md:block hidden">
              <div className="ml-10 flex items-baseline space-x-4">
                <SelectAI ai={ai} ais={ais} setAi={setAi} baseURL={baseURL} />
                <ApiKeyModal className="hidden" apiKey={apiKey} setApiKey={setApiKey} />
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              <button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" onClick={toggleMenu}>
                <span className="sr-only">View notifications</span>
                {/*<svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 9l-7 7-3-3" />
                </svg>*/}
              </button>

              <div className="ml-3 relative">
                <div>
                  <button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-haspopup="true">
                    <span className="sr-only">Open user menu</span>
                    {/*<img className="h-8 w-8 rounded-full" src="/user.jpg" alt="User" />*/}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button className="text-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" aria-expanded={isHamburgerOpen} onClick={toggleMenu}>
              <span className="sr-only">Open main menu</span>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                {isHamburgerOpen ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                ) : (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                )}
              </svg>
            </button>
          </div>
        </div>

        {isHamburgerOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <SelectAI ai={ai} ais={ais} setAi={setAi} baseURL={baseURL} />
              <ApiKeyModal className="hidden" apiKey={apiKey} setApiKey={setApiKey} />
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
