import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { OpenAI } from "openai";

import SendIcon from '@mui/icons-material/Send';


const InputBox = ({ baseURL, messages1, setMessages1, messages2, setMessages2, messages3, setMessages3, userMessages, setUserMessages, chat1, chat2, chat3, apiKey, setApiKey, ai, setIsKeyboardOpen }) => {
  const [rows, setRows] = useState(1);
  const [value, setValue] = useState('');
  const [lastMessage, setLastMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [lastSentIndex, setLastSentIndex] = useState(0);
  const inputRef = useRef(null);

  const [_phraseIndex, _setPhraseIndex] = useState(0);
  const [_isPunc, _setIsPunc] = useState(false);

  const handleFocus = () => {
    setIsKeyboardOpen(true);
  };
  const handleBlur = () => {
    setIsKeyboardOpen(false);
  };


  const openai = useMemo(() => new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true,
  }), [apiKey])

  const handleChange = (event) => {
    const textareaLineHeight = 30;
    const previousRows = event.target.rows;
    event.target.rows = 1;
    const currentRows = Math.min(100, ~~(event.target.scrollHeight / textareaLineHeight));

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }
    setRows(currentRows < 1 ? 1 : currentRows);
    const text = event.target.value;
    if (lastSentIndex > text.length) {
      setLastSentIndex(0);
      setUserMessages([]);
      setMessages1({});
      setMessages3({});
    }
    const char = text.slice(-1);
    setValue(text);

    if (apiKey === -1) {
      setApiKey('') // shows the modal
      return false;
    }

    const regexPunc = /[\n.!?,;:]/g;
    if (/[\n.!?,;: ]/.test(char)) {
      const isPunc = regexPunc.test(char);
      let lastPuncIndex = 0;
      let match;
      let phraseIndex = 0;
      while ((match = regexPunc.exec(text.slice(0, text.length-1)))) {
        lastPuncIndex = match.index;
        phraseIndex++;
      }
      // if (text.trim().split(' ').length === 10 || text.slice(lastPuncIndex, text.length).trim().split(' ').length % 10 === 0 || isPunc) {
      if (isPunc) {
        const phrase = isPunc
          ? text.slice(lastPuncIndex, text.length)
          : text.slice(lastSentIndex, text.length)
        if (phrase.trim().split(' ').length >= 10 || isPunc) {
          setLastSentIndex(text.length)

          if (phrase) {
            _setIsPunc(isPunc);
            _setPhraseIndex(phraseIndex);
            // const msg = (userMessages[phraseIndex] || '') + phrase;
            const msg = phrase;
            setUserMessages(prev => ({
              ...prev,
              [phraseIndex]: msg
            }))
          }
        }
      }
    }
  };

  useEffect(() => {
    ai && setMessages1([ai['yo']])
  }, [ai]);

  useEffect(() => {
    if (userMessages.length === 0) return;
    sendAllMessages(1, _phraseIndex, _isPunc)
    // sendAllMessages(3, _phraseIndex, _isPunc)
  }, [userMessages]);

  const sendAllMessages = async (i, phraseIndex, isPunc) => {
    setLoading(true);

    const _messages = i === 3 ? messages3 : messages1;
    const _setMessages = i === 3 ? setMessages3 : setMessages1;
    const _chat = i === 3 ? chat3 : chat1;

    const promptBoss = ai['prompt'] //"strictly make the given sentence professional sounding. do NOT respond with anything esle.";
    const promptGF = "strictly rewrite the given sentence for a romantic partner but casual sounding. do NOT respond with anything esle.";
    const prompt = i === 3 ? promptGF : promptBoss;
    const msgs = []
    if (Object.keys(userMessages).length === 0) {
      // msgs.push({ text: 'hi', sender: 'user' })
    } else {
      Object.keys(userMessages).map((key) => {
        msgs.push({ text: userMessages[key], sender: 'user' })
        msgs.push({ text: _messages[key], sender: 'assistant' })
      })
    }
    // const msgs = [..._messages, { text: message, sender: 'user' }];
    // _setMessages(messages => msgs);

    try {
      // const stream = await openai.chat.completions.create({
      //   model: "gpt-4-1106-preview",
      //   messages: [{ role: "system", content: prompt }, ...msgs.map(msg => ({role: msg.sender, content: msg.text}))],
      //   stream: true,
      // });
      // console.log([{ role: "system", content: prompt }, ...msgs.map(msg => ({role: msg.sender, content: msg.text}))])

      const response = await fetch(`${baseURL}/stream`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: "gpt-4-1106-preview",
          messages: [{ role: "system", content: prompt }, ...msgs.filter(x => x.text).map(msg => ({ role: msg.sender, content: msg.text }))],
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      let txt = isPunc || value.split(' ').length < 4 ? '' : _messages[phraseIndex] || '';
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      // Since this is a stream, we continuously read from it
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        // Decode the stream chunk to text
        const chunkText = decoder.decode(value, {stream: true});
        txt += chunkText;


      // for await (const chunk of stream) {
      //   const message = chunk.choices[0]?.delta?.content || '';
      //   txt += message;
      




        // _setMessages(messages => [...messages, { text: message, sender: 'assistant' }]);

        // _setMessages(prev => ({
        //   ...prev,
        //   [phraseIndex]: txt
        // }))
        // isPunc
        // ? setMessages1(prev => ({
        //     ...prev,
        //     [phraseIndex]: txt
        //   }))
        // : setMessages1(prev => ({
        //     ...prev,
        //     [phraseIndex]: (messages1[phraseIndex] || '') + message
        //   }))
        _chat.current.scrollTop = _chat.current.scrollHeight;
        let ii = 0;
        const lower = () => {          
          setTimeout(() => {
            _chat.current.scrollTop = _chat.current.scrollHeight;
            if (ii < 3) lower();
            ii++;
          }, 1000);
        }
        lower();

        // console.log(txt, phraseIndex)
        _setMessages(prev => ({
          ...prev,
          [phraseIndex]: txt.replace('{{user}}', 'you') + ' '
        }))
      }
      // _setMessages(messages => [...messages, { text: ' ', sender: 'assistant' }]);
    } catch (error) {
      console.error('Error during API call:', error);
    }
    setLoading(false);
  };

  return (
    <div className="bg-700 relative max-w-3xl mx-auto w-full p-2 pb-0 pt-0">
      <textarea
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={inputRef}
        rows={rows}
        autoFocus="1"
        value={value}
        onChange={handleChange}
        // onKeyDown={handleSubmit}
        className="custom-input max-h-screen bg-primary w-full p-4 pr-2 border-2 border-gray-600 rounded-lg resize-none outline-none"
        placeholder="Start typing a few words..."
      />
      {/*<div className="text-sm">* pasting does not work currently</div>*/}
    </div>
  );
};

export default InputBox;
