import { useState, useRef, useEffect } from 'react';
import './App.css';
import InputBox from './InputBox';
import NavBar from './NavBar';
import CopyToClipboardButton from './CopyToClipboardButton';

import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SelectAI from './SelectAI';

const baseURL = 'https://typeof.us/api';
function App() {
  const [apiKey, setApiKey] = useState(process.env.REACT_APP_OPENAI_API_KEY || -1);
  const [userMessages, setUserMessages] = useState([]);
  const [messages1, setMessages1] = useState({});
  const [messages2, setMessages2] = useState([]);
  const [messages3, setMessages3] = useState({});
  const chat1 = useRef(null);
  const chat2 = useRef(null);
  const chat3 = useRef(null);
  const [ais, setAis] = useState([]);
  const [ai, setAi] = useState();
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryParamValue = queryParams.get('apiKey')
    if (queryParamValue) {
      localStorage.setItem('apiKey', queryParamValue);
    }
    const savedValue = localStorage.getItem('apiKey');
    if (savedValue) {
      setApiKey(savedValue);
    }
  }, []);

  useEffect(() => {
    fetch(`${baseURL}/ais`)
      .then(response => response.json())
      .then(data => {
        setAis(data)
        setAi(data[0])
      });
  }, []);

  useEffect(() => {
    setUserMessages([])
    setMessages1({})
  }, [ai]);

  useEffect(() => {
    if (chat1.current) {
      chat1.current.scrollTop = chat1.current.scrollHeight;
    }
  }, [messages1]);


  return (
    <>
      <NavBar apiKey={apiKey} setApiKey={setApiKey} ai={ai} ais={ais} setAi={setAi} baseURL={baseURL} />

      <div className="bg-primary w-full mx-auto fixed bottom-0 left-0 right-0">
        <div className="flex flex-col">
          {ai && (
            <>
            <div className="p-4 bg-gray-800 flex items-center">
                <img
                  className="rounded-full w-12 h-12 md:w-12 md:h-12 mr-2"
                  src={`${baseURL}/${ai['image']}`}
                  alt="Avatar"
                />
              <h1 className="text-secondary text-xl">{ai ? ai.name : ''}</h1>
              {Object.keys(messages1).length > 0 && <CopyToClipboardButton contentRef={chat1} />}
            </div>
            <div className="flex-grow overflow-scroll overflow-scroll-webkit text-primary m-5" style={{ maxHeight: `${window.innerHeight*0.6}px` }} ref={chat1}>
              {Object.keys(messages1).length > 0 ? Object.keys(messages1).map((key) => (
                  <div>{messages1[key]}</div>
                )) : ai['yo']}
            </div>
            </>
          )}
        </div>
        <div className="pt-4 bg-gray-700 rounded-md shadow-md text-white">
          <InputBox
            setIsKeyboardOpen={setIsKeyboardOpen}
            baseURL={baseURL}
            messages1={messages1}
            setMessages1={setMessages1}
            messages2={messages2}
            messages3={messages3}
            setMessages2={setMessages2}
            setMessages3={setMessages3}
            userMessages={userMessages}
            setUserMessages={setUserMessages}
            chat1={chat1}
            apiKey={apiKey}
            setApiKey={setApiKey}
            ai={ai}
          />
        </div>
      </div>
    </>
  );
}

export default App;
